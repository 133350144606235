import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import { skipToken, useQuery } from '@tanstack/react-query';

const useGetServiceDivisionById = (divisionId: string | undefined) => {
  const organizationName = useOrganizationName();

  return useQuery({
    queryKey: ['service-division', divisionId, organizationName],
    queryFn: divisionId
      ? () => api.serviceDivision.getServiceDivisionById(divisionId)
      : skipToken
  });
};

export default useGetServiceDivisionById;
