import { z } from 'zod';
import {
  birthdateSchema,
  emailSchema,
  firstNameSchema,
  identificationSchema,
  lastNameSchema,
  phoneSchema
} from './common/schemas';

const userSchema = (props: { minAge?: number; maxAge?: number }) =>
  z.object({
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    identification: identificationSchema,
    birthdate: birthdateSchema(props),
    location: z.string().min(1, 'Este campo no puede estar vacío'),
    insurance: z.string().min(1, 'Este campo es obligatorio'),
    size: z.string().min(1, 'Este campo es obligatorio'),
    holidayDate: z.string().min(1, 'Este campo es obligatorio'),
    isEmployeePartner: z.string().min(1, 'Este campo es obligatorio'),
    isPartner: z.boolean({ required_error: 'Este campo es obligatorio' }),
    isExtension: z.boolean({ required_error: 'Este campo es obligatorio' }),
    group: z.string().min(1, 'Este campo no puede estar vacío').optional()
  });

const questionsSchema = z.discriminatedUnion(
  'value',
  [
    z.object({
      question: z.string(),
      placeholder: z.string().optional(),
      value: z.literal<boolean>(true, {
        invalid_type_error: 'Este campo es obligatorio'
      }),
      answer: z
        .string({ required_error: 'Este campo es obligatorio' })
        .min(1, 'Este campo no puede estar vacío')
    }),
    z.object({
      question: z.string(),
      value: z.literal<boolean>(false, {
        invalid_type_error: 'Este campo es obligatorio'
      }),
      answer: z
        .string()
        .optional()
        .transform(() => '')
    })
  ],
  {
    errorMap: (issue, ctx) => ({
      message:
        issue.code === 'invalid_union_discriminator'
          ? `Este campo es obligatorio`
          : ctx.defaultError
    })
  }
);
export type TQuestionsSchema = z.infer<typeof questionsSchema>;

const authorizedToPickUpSchema = z.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  identification: identificationSchema,
  phone: phoneSchema
});

const parentsSchema = z.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  connection: z.string().min(1, 'Este campo no puede estar vacío'),
  identification: identificationSchema,
  phone: phoneSchema,
  email: emailSchema
});

const formParticipantDataVpSchema = ({
  minAge,
  maxAge
}: {
  minAge?: number;
  maxAge?: number;
}) => {
  return z.object({
    user: userSchema({ minAge, maxAge }),
    parents: z.array(parentsSchema),
    authorizedToPickUp: authorizedToPickUpSchema,
    experience: z.string().min(1, 'Este campo no puede estar vacío'),
    diseases: z.array(questionsSchema),
    treatments: z.array(questionsSchema),
    observations: z.string().optional()
  });
};

export default formParticipantDataVpSchema;
