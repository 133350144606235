import type { TDateRange } from '@/pages/protected/CalendarV1';
import api from '@/services/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';

export type GetReservationsProps = {
  pagination: PaginationState;
  filtering: string;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
};

const getQueryOptions = (dateRange: TDateRange) => {
  return {
    queryKey: ['reservations', 'category', dateRange],
    queryFn: () => api.reservations.getReservationsByCategory(dateRange)
  };
};

const useGetReservationsByCategory = (dateRange: TDateRange) => {
  return useQuery({
    ...getQueryOptions(dateRange),
    placeholderData: keepPreviousData
  });
};

export default useGetReservationsByCategory;
