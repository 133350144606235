import { DESCRIPTION_MAX_LENGTH } from '@/utils/utils';
import { z } from 'zod';
import {
  allowPaymentSchema,
  canUpdateReservationsSchema,
  visibilitySchema
} from './common/schemas';

const serviceSchema = z
  .object({
    name: z
      .string({ required_error: 'schemas.service.nameRequired' })
      .min(2, {
        message: 'schemas.service.nameMin'
      })
      .max(100, 'schemas.service.nameMax'),
    description: z
      .string()
      .max(
        DESCRIPTION_MAX_LENGTH.long,
        `schemas.service.descriptionMax|{"maxLength": ${DESCRIPTION_MAX_LENGTH.long}}`
      )
      .optional(),
    price: z.coerce
      .number({
        required_error: 'schemas.service.priceRequired',
        invalid_type_error: 'schemas.service.priceRequired'
      })
      .min(0, { message: 'schemas.service.priceMin' }),
    reservationPct: z.coerce
      .number({
        required_error: 'schemas.service.reservationPctRequired',
        invalid_type_error: 'schemas.service.reservationPctRequired'
      })
      .min(0, { message: 'schemas.service.reservationPctMin' })
      .max(100, { message: 'schemas.service.reservationPctMax' }),
    minPreReservationTime: z.coerce
      .number({
        required_error: 'schemas.service.minPreReservationTimeRequired',
        invalid_type_error: 'schemas.service.minPreReservationTimeRequired'
      })
      .min(0, {
        message: 'schemas.service.minPreReservationTimeMin'
      }),
    sport: z.string().uuid(),
    allowPayment: allowPaymentSchema,
    visibility: visibilitySchema,
    canUpdateReservations: canUpdateReservationsSchema,
    minConsumers: z.coerce
      .number({ required_error: 'schemas.service.minConsumersRequired' })
      .min(1, { message: 'schemas.service.minConsumersMin' }),
    maxConsumers: z.coerce.number({
      required_error: 'schemas.service.maxConsumersRequired'
    }),
    minAge: z.coerce
      .number({
        required_error: 'schemas.service.minAgeRequired'
      })
      .transform((val) => (val === 0 || val === undefined ? null : val)),
    maxAge: z.coerce
      .number({
        required_error: 'schemas.service.maxAgeRequired'
      })
      .transform((val) => (val === 0 || val === undefined ? null : val))
  })
  .superRefine((obj, ctx) => {
    if (obj.minConsumers > obj.maxConsumers) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'schemas.service.minConsumersMax',
        path: ['minConsumers']
      });
    }

    if (obj.maxAge) {
      if (obj.minAge && obj.minAge > obj.maxAge) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'schemas.service.minAgeMax',
          path: ['minAge']
        });
      }
    }
  });

export default serviceSchema;
