import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import { skipToken, useQuery } from '@tanstack/react-query';

const useGetOrganization = () => {
  const organizationName = useOrganizationName();

  return useQuery({
    queryKey: ['Getorganization', organizationName],
    queryFn:
      organizationName === undefined
        ? skipToken
        : () => api.organization.getOrganization(organizationName),

    staleTime: 24 * 60 * 60 * 1000
  });
};

export default useGetOrganization;
