import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from '@/components/ui/tooltip';
import { TConsumer } from '@/services/consumer';
import { getEmojiStatus } from '@/utils/consumer';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StudentStatusEmojis: React.FC<{ studentData: TConsumer }> = ({
  studentData
}) => {
  const { t } = useTranslation();
  const studentStatusEmojis = getEmojiStatus(studentData);

  return studentStatusEmojis.map(({ emoji, status }) => (
    <div>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <div>{emoji}</div>
          </TooltipTrigger>
          <TooltipContent>
            <p>{t(`studentStatus.${status}`)}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  ));
};

export default StudentStatusEmojis;
