import { getGroup } from '@/lib/utils';
import { parseDateToDMY } from '@/utils/utils';
import * as XLSX from 'xlsx';
import type { ReservationInterface } from '../tables.types';
import { getTimeZone } from '@/utils/dates';
import { differenceInYears, format, parse, compareAsc } from 'date-fns';
import { ReservationStatus } from '@/utils/reservations';

type GroupedData = {
  Apellido: string;
  Nombre: string;
  Edad: string;
  Talle: string;
  Contacto: string;
  Extension: string;
  'Manejo en el agua': string;
  Alergias: string;
  'Tratamiento Medico': string;
  Observaciones: string;
  'Fecha de Nacimiento': string;
};

export const exportDataOfStudentsToExcel = (
  data: ReservationInterface[] | undefined
) => {
  const workbook = XLSX.utils.book_new();

  const groupedData: { [key: string]: GroupedData[] } = {};
  const addedCustomerIds = new Set<string>();

  if (data) {
    // biome-ignore lint/complexity/noForEach: <explanation>
    data.forEach((reservation) => {
      const consumer = reservation.consumers[0];
      if (!consumer || addedCustomerIds.has(consumer.id)) return;

      const additionalData = consumer?.additionalData;

      const ageRange = getGroup(additionalData);

      if (!groupedData[ageRange]) {
        groupedData[ageRange] = [];
      }

      const bornDate = consumer.birthdate
        ? parseDateToDMY(consumer.birthdate)
        : 'No disponible';

      const parent1 = `${consumer.additionalData.parents[0]?.connection || 'N/A'}: ${consumer.additionalData.parents[0]?.phone || 'N/A'}`;
      const parent2 = `${consumer.additionalData.parents[1]?.connection || 'N/A'}: ${consumer.additionalData.parents[1]?.phone || 'N/A'}`;

      groupedData[ageRange].push({
        Apellido: consumer.lastName,
        Nombre: consumer.firstName,
        Edad:
          differenceInYears(new Date(), consumer.birthdate).toString() || '',
        Talle: consumer.additionalData.size || 'NO',
        Contacto: `${parent1}, ${parent2}`,
        Extension: consumer.additionalData.isExtension ? 'SI' : 'NO',
        'Manejo en el agua': consumer.additionalData.experience,
        Alergias: consumer.additionalData.diseases[2]?.value
          ? `SI, ${consumer.additionalData.diseases[2].answer}`
          : 'NO',
        'Tratamiento Medico': consumer.additionalData.treatments[2]?.value
          ? `SI, ${consumer.additionalData.treatments[2].answer}`
          : 'NO',
        Observaciones: consumer.additionalData.observations || '',
        'Fecha de Nacimiento': bornDate
      });

      addedCustomerIds.add(consumer.id);
    });

    // biome-ignore lint/complexity/noForEach: <explanation>
    Object.keys(groupedData).forEach((group) => {
      const groupData = groupedData[group];

      const headers = Object.keys(groupData[0] || {});
      const worksheet = XLSX.utils.json_to_sheet([]);

      XLSX.utils.sheet_add_aoa(worksheet, [[`Grupo: ${group}`]], {
        origin: 'A1'
      });

      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A2' });

      XLSX.utils.sheet_add_json(worksheet, groupData, {
        origin: 'A3',
        skipHeader: true
      });

      XLSX.utils.book_append_sheet(workbook, worksheet, group);
    });
  }

  // Descargar el archivo
  XLSX.writeFile(workbook, 'DatosDeAlumnosPorGrupo.xlsx');
};

const formatDate = (timestamp: number) => {
  const date = getTimeZone(timestamp, 'America/Argentina/Buenos_Aires');
  return format(date, 'dd/MM/yyyy');
};

// Export attendance
export const exportAttendanceToExcel = (data: any[]) => {
  const workbook = XLSX.utils.book_new();

  // Mapa para organizar los datos por rango de edad
  const groupedData: { [key: string]: any[] } = {};
  const allDates = new Set<string>();

  // biome-ignore lint/complexity/noForEach: <explanation>
  data.forEach((reservation) => {
    const consumer = reservation.consumers[0];
    if (!consumer) return;
    const additionalData = consumer?.additionalData;

    const ageRange = getGroup(additionalData);

    if (!groupedData[ageRange]) {
      groupedData[ageRange] = [];
    }

    const date = formatDate(reservation.startTime);
    allDates.add(date);

    let consumerData = groupedData[ageRange].find(
      (entry) =>
        entry.Apellido === consumer.lastName &&
        entry.Nombre === consumer.firstName
    );

    if (!consumerData) {
      consumerData = {
        Apellido: consumer.lastName,
        Nombre: consumer.firstName
      };
      groupedData[ageRange].push(consumerData);
    }
    const status = () => {
      if (reservation.reservationStatus?.name === ReservationStatus.Completed) {
        return 'Presente';
      }
      if (reservation.reservationStatus?.name === ReservationStatus.Absent) {
        return 'Ausente';
      }
      return '';
    };
    consumerData[date] = status();
  });

  const sortedDates = Array.from(allDates).sort((a, b) => {
    const dateA = parse(a, 'dd/MM/yyyy', new Date());
    const dateB = parse(b, 'dd/MM/yyyy', new Date());
    return compareAsc(dateA, dateB);
  });

  // biome-ignore lint/complexity/noForEach: <explanation>
  Object.keys(groupedData).forEach((group) => {
    const groupDataInOrder = groupedData[group].map((row) => {
      // biome-ignore lint/complexity/noForEach: <explanation>
      sortedDates.forEach((date) => {
        if (!row[date]) row[date] = '';
      });
      return {
        Apellido: row.Apellido,
        Nombre: row.Nombre,
        ...sortedDates.reduce(
          (acc, currentDate) => {
            acc[currentDate] = row[currentDate];
            return acc;
          },
          {} as Record<string, string>
        )
      };
    });

    const headers = ['Apellido', 'Nombre', ...sortedDates];
    const worksheet = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(worksheet, [[`Grupo: ${group}`]], {
      origin: 'A1'
    });

    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A2' });

    XLSX.utils.sheet_add_json(worksheet, groupDataInOrder, {
      origin: 'A3',
      skipHeader: true
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, group);
  });

  XLSX.writeFile(workbook, 'AsistenciasPorGrupo.xlsx');
};
