import IdentificationTypeSelect from '@/components/common/input/identification-types-select/IdentificationTypeSelect';
import { Button } from '@/components/ui/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useRootStore } from '@/stores/root-store';
import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AuthorizedToPickUpForm: React.FC<{
  form: UseFormReturn<any, any, undefined>;
  formField: 'authorizedToPickUp';
  onSave: () => Promise<void>;
}> = ({ form, formField, onSave }) => {
  const { t } = useTranslation();

  const cleanConsumers = useRootStore((store) => store.cleanConsumers);
  const loadStepServicesData = useRootStore(
    (store) => store.loadStepServicesData
  );

  const handleValidate = () => {
    onSave();
    const data = form.getValues();
    cleanConsumers('user');
    loadStepServicesData(data.user);
  };

  return (
    <div
      className="flex flex-col gap-8 p-6 mb-3 border rounded-md border-primary"
      key={`participant-participant`}
    >
      <FormField
        control={form.control}
        name={`${formField}.firstName`}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('stepParticipants.form.firstName')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={`${formField}.lastName`}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('stepParticipants.form.lastName')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={`${formField}.phone`}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('phone')}</FormLabel>
              <FormControl>
                <Input
                  type="tel"
                  {...field}
                  placeholder={t('common.placeholder.phone')}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={`${formField}.identification.type`}
        render={({ field }) => {
          return (
            <FormItem>
              <IdentificationTypeSelect
                defaultValue={field?.value}
                onValueChange={(value) => {
                  field.onChange(value);
                }}
                field={field}
              />
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={`${formField}.identification.number`}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>
                {t('stepParticipants.form.identificationNumber')}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={t('stepParticipants.form.id')}
                  {...field}
                  value={field?.value || ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <Button type="button" onClick={handleValidate}>
        {t('common.validate')}
      </Button>
    </div>
  );
};

export default AuthorizedToPickUpForm;
