import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import useFormattedDate from '@/hooks/useFormattedDate';
import { cn } from '@/lib/utils';
import { t } from 'i18next';
import { CalendarIcon } from 'lucide-react';
import { useState } from 'react';
import type { CalendarModalT } from './CalendarModal.types';

const CalendarModal = ({
  value,
  onAccept,
  placeholder = t('form.birthdate'),
  trigger,
  onClickInputLabel,
  disabledInputLabel,
  onOpenChange,
  onCancel,
  ...props
}: CalendarModalT) => {
  const { formattedDate } = useFormattedDate();
  const [select, setSelect] = useState<Date | undefined>(value);

  const acceptButtonStatus = () => {
    if (select?.getTime() === value?.getTime()) return true;
    return !select;
  };

  const handleAccept = async () => {
    if (select) {
      onAccept(select);
    }
    if (trigger) {
      await trigger();
    }
  };

  return (
    <AlertDialog onOpenChange={onOpenChange}>
      <AlertDialogTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'text-left font-normal',
            !value && 'text-muted-foreground',
            placeholder && 'pl-3'
          )}
          onClick={onClickInputLabel}
          disabled={disabledInputLabel}
        >
          {value ? (
            formattedDate({ date: value, dateFormat: 'date' })
          ) : (
            <span>{placeholder}</span>
          )}
          <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <Calendar
            mode="single"
            selected={select}
            onSelect={async (date) => setSelect(date)}
            disabled={(date) =>
              date > new Date() || date < new Date('1900-01-01')
            }
            onMonthChange={() => setSelect(value)}
            defaultMonth={select}
            classNames={{
              row: 'flex w-full mt-2 justify-around',
              head_row: 'flex justify-around',
              months: 'flex flex-col space-y-4 sm:space-x-4 sm:space-y-0'
            }}
            initialFocus
            {...props}
          />
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              setSelect(value || undefined);
              if (onCancel) {
                onCancel();
              }
            }}
          >
            {t('common.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={acceptButtonStatus()}
            onClick={handleAccept}
          >
            {t('common.accept')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CalendarModal;
