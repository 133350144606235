import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetReservation = (reservationId: string) => {
  const organizationName = useOrganizationName();

  return useQuery({
    queryKey: ['reservation', reservationId, organizationName],
    queryFn: () => api.reservations.getReservationById(reservationId)
  });
};

export default useGetReservation;
