import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetIdentificationType = () => {
  return useQuery({
    queryKey: ['identificationType'],
    queryFn: () => api.identification.getIdentificationTypes(),
    staleTime: Number.POSITIVE_INFINITY
  });
};

export default useGetIdentificationType;
