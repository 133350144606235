import Navbar from '@/components/section/navbar/navbar';
import { AppSidebar } from '@/components/section/sidebar/AppSidebar';
import { SidebarProvider } from '@/components/ui/sidebar';
import useAuth from '@/hooks/useAuth';
import { Outlet } from 'react-router-dom';
import CallbackPage from './Callback';

const Root = () => {
  const { tokenIsPending } = useAuth();

  if (tokenIsPending) {
    return <CallbackPage />;
  }

  return (
    <SidebarProvider>
      <AppSidebar />
      <main className="relative flex-1 overflow-y-auto">
        <Navbar />
        <div className="px-4 py-4 mt-14 md:mt-[60px] md:px-6 md:py-6 bg-base-200">
          <Outlet />
        </div>
      </main>
    </SidebarProvider>
  );
};
export default Root;
