import EmojiLegendModal from '@/components/common/modals/emoji-legend-modal/EmojiLegendModal';
import GroupFilter from '@/components/common/student-row/GroupFilter';
import StudentAttendenceRow from '@/components/common/student-row/StudentAttendenceRow';
import { Skeleton } from '@/components/ui/skeleton';
import useGetReservationsByServiceId from '@/hooks/queries/reservations-api/useGetReservationByServiceId';
import useGetOrganizationServices from '@/hooks/queries/services-api/useGetOrganizationServices';
import type { TServices } from '@/services/services';
import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Attendance = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const today =
    language === 'es'
      ? format(new Date(), "d 'de' MMMM", { locale: es })
      : format(new Date(), 'MMMM d', { locale: enUS });
  const currentDateOnly = true;
  const [valueFilter, setValueFilter] = useState<string | undefined>();

  const { data: services, isLoading: isLoadingServices } =
    useGetOrganizationServices<TServices[]>();

  const SERVICE_ID =
    services?.filter((item) => item.enabled === true)[0]?.id ?? null;

  const { data, isLoading: isLoadingReservations } =
    useGetReservationsByServiceId(SERVICE_ID, currentDateOnly);

  const reservations = valueFilter
    ? data?.filter(
        (item) => item.consumers[0]?.additionalData?.group === valueFilter
      )
    : data;

  if (isLoadingServices || isLoadingReservations) {
    return (
      <div>
        <Skeleton className="h-8 w-60" />
        <Skeleton className="w-56 h-6 mt-1" />

        <div className="flex flex-col gap-2 mt-5">
          <Skeleton className="w-full h-14" />
          <Skeleton className="w-full h-14" />
          <Skeleton className="w-full h-14" />
        </div>
      </div>
    );
  }

  if (!services?.length) {
    return <p>{t('noServiceFound')}</p>;
  }

  return (
    <div>
      <h1 className="text-2xl font-bold">{t('activity.student.attendance')}</h1>
      <p className="mt-1">{today}</p>
      <div className="flex items-center gap-4 mt-6">
        <GroupFilter
          valueFilter={valueFilter}
          setValueFilter={setValueFilter}
        />
        <EmojiLegendModal />
      </div>
      <div className="flex flex-col gap-2 mt-6">
        {reservations?.map((item) => (
          <StudentAttendenceRow key={item.id} reservation={item} />
        ))}
      </div>
    </div>
  );
};

export default Attendance;
