import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import useFormattedDate from '@/hooks/useFormattedDate';
import type { TOrganizationFAQ } from '@/services/organization';
import { useRootStore } from '@/stores/root-store';
import { useQueryClient } from '@tanstack/react-query';
import { differenceInYears } from 'date-fns';

import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const RegistrationSummary: React.FC<{ organization: TOrganizationFAQ }> = ({
  organization
}) => {
  const { t } = useTranslation();
  const setStep = useRootStore((store) => store.setStep);
  const removeStep = useRootStore((store) => store.removeStep);
  const { formattedDate } = useFormattedDate();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const saleData: any = queryClient.getQueryData(['createSale']);

  if (!saleData) {
    removeStep(1);
    return;
  }

  const startTime = formattedDate({
    date: saleData.reservations[0].startTime,
    dateFormat: 'date'
  });

  const studentData = saleData.participants[0];
  const additionalData = studentData.additionalData;
  const parents = additionalData.parents;
  const authorizedToPickUp = additionalData.authorizedToPickUp;

  const content: {
    label: string;
    value: {
      label: string;
      value: string;
    }[];
  }[] = [
    {
      label: t('newBooking.inscriptionData'),
      value: [
        { label: t('card.course'), value: saleData.service?.name },
        { label: t('registrationSummary.information.curse'), value: startTime }
      ]
    },
    {
      label: t('activity.table.student'),
      value: [
        {
          label: t('form.firstName'),
          value: `${studentData?.firstName} ${studentData?.lastName}`
        },
        {
          label: t('form.birthdate'),
          value: studentData?.birthdate
            ? differenceInYears(new Date(), studentData?.birthdate)
            : ''
        },
        {
          label: t('form.medicalCoverage'),
          value: additionalData?.insurance
        },
        {
          label: t('form.tShirtSize'),
          value: additionalData?.size
        }
      ]
    },
    {
      label: t('responsible'),
      value: parents.map((parent: any, index: number) => ({
        label: t('parents', { number: index + 1 }),
        value: `${parent?.firstName} ${parent?.lastName} - ${parent?.connection}`
      }))
    },
    {
      label: t('activity.student.authorizedPickup'),
      value: [
        {
          label: t('form.firstName'),
          value: `${authorizedToPickUp?.firstName}, ${authorizedToPickUp?.lastName}`
        },
        {
          label: t('common.phone'),
          value: authorizedToPickUp?.phone
        }
      ]
    }
  ];

  return (
    <div className="flex flex-col gap-6">
      <div>
        <h3 className="mb-6 font-bold">{t('registrationSummary.title')}</h3>
        <Card className="w-full">
          <CardContent className="p-10 space-y-6">
            {content.map((value, index) => (
              <div key={`${value.label}-${index}`}>
                <h2 className="font-bold">{value.label}</h2>
                {value.value.map((obj, index) => (
                  <div
                    key={`${value.value}-${index}`}
                    className="flex gap-2 px-5 my-5"
                  >
                    <p>{obj.label}:</p>
                    <p>{obj.value}</p>
                  </div>
                ))}
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
      <div className="flex flex-col gap-4">
        <Button
          type="button"
          className="w-full"
          onClick={() => navigate(`/activity/students/${studentData.id}`)}
        >
          {t('registrationSummary.button.seeRegistration')}
        </Button>
        <Button
          type="button"
          variant="outline"
          className="w-full"
          onClick={() => setStep(1)}
        >
          {t('registrationSummary.button.anotherRegistration')}
        </Button>
      </div>
    </div>
  );
};

export default RegistrationSummary;
