import {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  defaultSorting?: SortingState;
};

const usePersistedPaginationFiltersAndSorting = ({
  defaultSorting
}: Props = {}) => {
  function arrayToQueryString(array: ColumnFiltersState) {
    const params = array.map((obj) => {
      const id = encodeURIComponent(obj.id);
      const isArr = Array.isArray(obj.value);
      let values;
      if (isArr) {
        const arrayOfValues = obj.value as string[];
        values = arrayOfValues.map((val) => encodeURIComponent(val)).join(',');
      } else {
        const objOfValues = obj.value as {
          from: number | undefined;
          to: number | undefined;
        };
        values = JSON.stringify(objOfValues);
      }

      return `${id}=${values}`;
    });
    return params.join('&');
  }
  function queryStringToArray(queryString: string) {
    const params = new URLSearchParams(queryString);
    const array = [];

    for (const [id, value] of params.entries()) {
      if (value.includes('to') || value.includes('from')) {
        array.push({ id: decodeURIComponent(id), value: JSON.parse(value) });
      } else {
        const valuesArray = value
          .split(',')
          .map((val) => encodeURIComponent(decodeURIComponent(val)));
        array.push({ id: decodeURIComponent(id), value: valuesArray });
      }
    }

    return array;
  }

  const [searchParams, setSearchParams] = useSearchParams();

  const initialPagination = {
    pageIndex: parseInt(searchParams.get('pageIndex') || '0', 10),
    pageSize: 20
  };

  const initialSorting = () => {
    const sortBy = searchParams.get('sortBy');
    if (sortBy) {
      return [
        {
          id: sortBy,
          desc: searchParams.get('desc') === 'true'
        }
      ];
    }
    if (defaultSorting) {
      return defaultSorting;
    }
    return [];
  };

  const initialColumnFilters = () => {
    const columnFilters = searchParams.get('columnFilters');
    return columnFilters ? queryStringToArray(columnFilters) : [];
  };

  const [pagination, setPagination] =
    useState<PaginationState>(initialPagination);
  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const [filtering, setFiltering] = useState(
    decodeURIComponent(searchParams.get('search') || '')
  );
  const [columnFilters, setColumnFilters] =
    useState<ColumnFiltersState>(initialColumnFilters);

  const isInitialLoad = useRef(true);

  useEffect(() => {
    const updateSearchParams = (prev: URLSearchParams) => {
      const newParams = new URLSearchParams(prev);
      if (sorting.length > 0) {
        const { id, desc } = sorting[0];
        newParams.set('sortBy', id);
        newParams.set('desc', desc.toString());
      } else {
        newParams.delete('sortBy');
        newParams.delete('desc');
      }
      newParams.set('pageIndex', pagination.pageIndex.toString());
      if (filtering) {
        newParams.set('search', encodeURIComponent(filtering));
      } else {
        newParams.delete('search');
      }
      if (columnFilters.length > 0) {
        const queryString = arrayToQueryString(columnFilters);
        newParams.set('columnFilters', queryString);
      } else {
        newParams.delete('columnFilters');
      }
      return newParams;
    };

    setSearchParams((prevParams) => updateSearchParams(prevParams));
  }, [setSearchParams, sorting, pagination, filtering, columnFilters]);

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
    } else {
      setPagination((prev) => ({
        ...prev,
        pageIndex: 0
      }));
    }
  }, [filtering, sorting, columnFilters]);

  return {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters
  };
};

export default usePersistedPaginationFiltersAndSorting;
