import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { HelpCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export default function EmojiLegendModal() {
  const { t } = useTranslation();
  const emojiMeanings = [
    { emoji: '🎉', meaning: t('studentStatus.birthday') },
    { emoji: '💊', meaning: t('studentStatus.treatment') },
    { emoji: '🤧', meaning: t('studentStatus.allergy') },
    { emoji: '😷', meaning: t('studentStatus.disease') },
    { emoji: '⚠️', meaning: t('studentStatus.concurrentAbsent') }
  ];

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <HelpCircle className="w-7 h-7" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-center">
            {t('emojiLegendModal.title')}
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-3">
          <ul className="space-y-2">
            {emojiMeanings.map(({ emoji, meaning }) => (
              <li key={emoji} className="flex items-center gap-3 text-sm">
                <span className="text-lg select-none">{emoji}</span>
                <span>{meaning}</span>
              </li>
            ))}
          </ul>
          <p className="text-xs italic">{t('emojiLegendModal.footer')}</p>
        </div>
      </DialogContent>
    </Dialog>
  );
}
