import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { ageGroups } from '@/lib/utils';
import { FilterIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface GroupFilterProps {
  disabled?: boolean;
  valueFilter: string | undefined;
  setValueFilter: (value: string | undefined) => void;
}

const GroupFilter = ({
  disabled,
  valueFilter,
  setValueFilter
}: GroupFilterProps) => {
  const { t } = useTranslation();

  const options = Object.keys(ageGroups).map((group, index) => ({
    label: t(`tables.group.${index + 1}`),
    value: group
  }));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          className="flex items-center justify-start"
          variant={valueFilter ? 'default' : 'outline'}
        >
          <FilterIcon className="w-4 h-4 mr-2" />
          {t('activity.student.group')}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-[200px] p-2 bg-white"
        align="start"
        side="right"
      >
        <div className="grid gap-2">
          {options.map((option) => {
            const isSelected = valueFilter === option.value;
            return (
              <Button
                key={option.value}
                onClick={() => {
                  isSelected
                    ? setValueFilter(undefined)
                    : setValueFilter(option.value);
                }}
                variant={isSelected ? 'default' : 'outline'}
              >
                {option.label}
              </Button>
            );
          })}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default GroupFilter;
