import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import { skipToken, useQuery } from '@tanstack/react-query';

const useGetServiceDivisionByServiceId = (serviceId: string | undefined) => {
  const organizationName = useOrganizationName();

  return useQuery({
    queryKey: ['service-division', serviceId, organizationName],
    queryFn: serviceId
      ? () => api.serviceDivision.getServiceDivisionByServiceId(serviceId)
      : skipToken
  });
};

export default useGetServiceDivisionByServiceId;
