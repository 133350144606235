import { Checkbox } from '@/components/ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetReservationByConsumer from '@/hooks/queries/reservations-api/useGetReservationByConsumer';
import useReservationActions from '@/hooks/useReservationActions';
import { getTimeZone } from '@/utils/dates';
import { ReservationStatus } from '@/utils/reservations';

const StudentAttendanceDays: React.FC<{
  consumerId?: string;
  disabledDays?: boolean;
}> = ({ consumerId, disabledDays }) => {
  const { data: reservations, isPending } =
    useGetReservationByConsumer(consumerId);
  const organization = useGetOrganization();
  const {
    handleAbsent,
    handleCompleted,
    isPending: pendingAttendance
  } = useReservationActions({ disabledRefetch: true });

  const reservationDates = reservations?.map((data) => {
    const checkPresent =
      data.reservationStatus.name === ReservationStatus.Completed;
    const reservationId = data.id;

    return {
      dateString:
        organization.data?.tz &&
        getTimeZone(data.startTime, organization.data.tz),
      checkPresent,
      reservationId
    };
  });

  const datesDayAndMonth = reservationDates?.map(
    ({ dateString, checkPresent, reservationId }) => {
      const date = dateString && new Date(dateString);
      const day = date instanceof Date && date?.getDate();
      const month = date instanceof Date && date?.getMonth() + 1;
      return {
        date: `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`,
        checkPresent,
        reservationId
      };
    }
  );
  const handleAttendance = (reservationId: string, checkPresent: boolean) => {
    if (checkPresent) {
      handleAbsent(reservationId);
    } else {
      handleCompleted(reservationId);
    }
  };

  return (
    <div className="space-y-4">
      <Select defaultValue="colonia-2025">
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Seleccionar período" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="colonia-2025">Colonia 2025</SelectItem>
        </SelectContent>
      </Select>

      <div className="grid grid-cols-4 gap-2 sm:grid-cols-6 md:grid-cols-12">
        {isPending ? (
          <>
            {Array.from({ length: 12 }).map((_, index) => (
              <Skeleton
                key={index}
                className="w-6 h-6 bg-slate-300 justify-self-center"
              />
            ))}
          </>
        ) : (
          <>
            {datesDayAndMonth?.map((data, idx) => (
              <div
                key={`${data.date}-${idx}`}
                className="flex flex-col items-center"
              >
                <label
                  htmlFor={data.date}
                  className={`mb-1 text-sm ${disabledDays ? 'text-muted-foreground' : ''}`}
                >
                  {data.date}
                </label>
                <Checkbox
                  id={data.date}
                  className="flex items-center justify-center w-6 h-6 border rounded-sm"
                  disabled={disabledDays || pendingAttendance}
                  checked={data.checkPresent}
                  onClick={() =>
                    handleAttendance(data.reservationId, data.checkPresent)
                  }
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default StudentAttendanceDays;
