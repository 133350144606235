import { DESCRIPTION_MAX_LENGTH } from '@/utils/utils';
import { z } from 'zod';

const serviceDivisionSchema = z.object({
  name: z
    .string({ required_error: 'schemas.service.nameRequired' })
    .min(2, {
      message: 'schemas.service.nameMin'
    })
    .max(100, 'schemas.service.nameMax'),
  description: z
    .string()
    .max(
      DESCRIPTION_MAX_LENGTH.medium,
      `schemas.service.descriptionMax|{"maxLength": ${DESCRIPTION_MAX_LENGTH.medium}}`
    )
    .optional(),
  duration: z.coerce
    .number({
      required_error: 'schemas.serviceDivision.durationRequired',
      invalid_type_error: 'schemas.serviceDivision.durationRequired'
    })
    .min(1, { message: 'schemas.serviceDivision.durationMin' }),
  quantity: z.coerce
    .number({
      required_error: 'schemas.serviceDivision.quantityRequired',
      invalid_type_error: 'schemas.serviceDivision.quantityRequired'
    })
    .min(1, { message: 'schemas.serviceDivision.quantityMin' })
});

export default serviceDivisionSchema;
