import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { useTranslation } from 'react-i18next';

export default function LanguageChanger() {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const getPrimaryLanguage = (locale: string) => {
    if (locale.includes('-')) {
      return locale.split('-')[0];
    } else {
      return locale;
    }
  };
  const primaryLanguage = getPrimaryLanguage(currentLocale);
  const handleChangeLanguage = (value: string) => {
    if (value === 'es') i18n.changeLanguage('es');
    else i18n.changeLanguage('en');
  };

  return (
    <Select value={primaryLanguage} onValueChange={handleChangeLanguage}>
      <SelectTrigger className="justify-between w-28">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="es">{t('common.language.spanish')}</SelectItem>
          <SelectItem value="en">{t('common.language.english')}</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
