import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import { PlusCircleIcon } from 'lucide-react';

interface Option {
  label: string;
  value: string;
}

interface GroupFilterProps {
  disabled?: boolean;
  title: string;
  options: Option[];
  column: any;
}

const GroupFilter = ({
  disabled,
  title,
  options,
  column
}: GroupFilterProps) => {
  const selectedValues = new Set(column?.getFilterValue() as string[]);
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          disabled={disabled}
          className="flex items-center justify-start w-full"
        >
          <PlusCircleIcon className="w-4 h-4 mr-2" />
          {title}
          {selectedValues.size > 0 && (
            <>
              <Separator orientation="vertical" className="h-4 mx-2" />

              <Badge
                variant="secondary"
                className="px-1 font-normal rounded-sm "
              >
                1
              </Badge>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-[200px] p-2 bg-white"
        align="start"
        side="right"
      >
        <div className="grid gap-2">
          {options.map((option) => {
            const isSelected = selectedValues.has(
              encodeURIComponent(option.value)
            );
            return (
              <Button
                key={option.value}
                onClick={() => {
                  const filterValues = isSelected
                    ? []
                    : [encodeURIComponent(option.value)];
                  column?.setFilterValue(
                    filterValues.length ? filterValues : undefined
                  );
                }}
                variant={isSelected ? 'default' : 'outline'}
              >
                {option.label}
              </Button>
            );
          })}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default GroupFilter;
