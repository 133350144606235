import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import type { TServices } from '@/services/services';
import { useQuery } from '@tanstack/react-query';

const useGetOrganizationServices = <TResult>(
  select?: (sales: TServices[]) => TResult
) => {
  const organizationName = useOrganizationName();

  return useQuery({
    queryKey: ['services', organizationName],
    queryFn: () => api.services.getServices(),
    select
  });
};

export const useGetServicesById = (id: string) =>
  useGetOrganizationServices<TServices | undefined>((sales) =>
    sales.find((sale) => sale.id === id)
  );

export default useGetOrganizationServices;
