import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetConsumer = (id: string | undefined) => {
  const organizationName = useOrganizationName();

  return useQuery({
    queryKey: ['consumers', id, organizationName],
    queryFn: () => (id ? api.consumer.getConsumerById(id) : undefined),
    enabled: Boolean(id)
  });
};
export default useGetConsumer;
