import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetConsumer = () => {
  const organizationName = useOrganizationName();

  return useQuery({
    queryKey: ['consumers', organizationName],
    queryFn: () => api.consumer.getConsumers(),
    retry: false,
    staleTime: 1000 * 60 * 10,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
};
export default useGetConsumer;
