import { Button } from '@/components/ui/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel
} from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Textarea } from '@/components/ui/textarea';
import { useFieldArray, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Questionsform: React.FC<{
  form: UseFormReturn<any, any, undefined>;
  formField: 'treatments' | 'diseases';
}> = ({ form, formField }) => {
  const { t } = useTranslation();

  const { fields } = useFieldArray({
    control: form.control,
    name: formField
  });

  return (
    <div
      className="grid gap-6 md:grid-cols-2"
      key={'questions-form'}
    >
      {fields.map((_field, index) => {
        const errors = (
          form.formState.errors[formField] as any[] | undefined
        )?.[index];
        const answerError = errors?.answer;
        return (
          <FormField
            key={`${formField}.${index}`}
            control={form.control}
            name={`${formField}.${index}`}
            render={({ field }) => {
              const defaultValue =
                field.value.value === undefined
                  ? undefined
                  : field.value.value
                    ? 'yes'
                    : 'no';
              return (
                <FormItem>
                  <div className="flex flex-col  gap-5">
                    <FormLabel>{field.value.question}</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={(value) =>
                          field.onChange({
                            ...field.value,
                            value: value === 'yes'
                          })
                        }
                        defaultValue={defaultValue}
                        className="flex items-center mt-0 space-x-2"
                      >
                        <FormItem className="flex items-center space-x-1 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="yes" />
                          </FormControl>
                          <FormLabel className="font-normal">
                            {t('form.yes')}
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-1 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="no" />
                          </FormControl>
                          <FormLabel className="font-normal">
                            {t('form.no')}
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                  </div>

                  {field?.value?.value && (
                    <div className="px-2 py-1">
                      <Textarea
                        placeholder={
                          field?.value?.placeholder || t('newBooking.extraInfo')
                        }
                        {...field}
                        onChange={(e) =>
                          field.onChange({
                            ...field.value,
                            answer: e.target.value
                          })
                        }
                        value={field?.value?.answer || ''}
                      />
                      {answerError && (
                        <p className="text-sm font-medium text-destructive">
                          {answerError.message}
                        </p>
                      )}
                    </div>
                  )}
                </FormItem>
              );
            }}
          />
        );
      })}
    </div>
  );
};

export default Questionsform;
