import { date, string, z } from 'zod';

const addPaymentSchema = (amountPending: number, amountRegistered?: number) =>
  z
    .object({
      user: string().optional(),
      service: string().optional(),
      amount: z.coerce
        .number({ required_error: 'schemas.addPayment.amountRequired' })
        .min(1, 'schemas.addPayment.amountMin'),
      paymentMethod: string({
        required_error: 'schemas.addPayment.aymentMethodRequired'
      }).min(1, 'schemas.addPayment.paymentMethodValid'),
      amountPending: string(),
      total: string().optional(),
      comments: string().optional(),
      date: date({ required_error: 'schemas.common.dateRequired' })
    })
    .refine(
      (data) => {
        const amount = data.amount;
        let pending;
        if (amountRegistered) {
          pending = amountRegistered + amountPending;
        } else {
          pending = amountPending;
        }
        return amount <= pending;
      },
      {
        message: 'schemas.addPayment.messageError',
        path: ['amount'] //Path of error
      }
    );

export default addPaymentSchema;
