import api from '@/services/api';
import {
  keepPreviousData,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { useEffect } from 'react';

export type GetEquipmentsProps = {
  pagination: PaginationState;
  filtering: string;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
};

const getQueryOptions = ({
  pagination,
  filtering,
  sorting,
  columnFilters
}: GetEquipmentsProps) => {
  return {
    queryKey: [
      'equipments',
      pagination,
      { search: filtering },
      sorting,
      columnFilters
    ],
    queryFn: () =>
      api.equipments.getPaginatedEquipments({
        pagination,
        filtering,
        sorting,
        columnFilters
      })
  };
};

const useGetPaginatedEquipments = (props: GetEquipmentsProps) => {
  const queryClient = useQueryClient();

  //prefetch next page
  useEffect(() => {
    queryClient.prefetchQuery(
      getQueryOptions({
        ...props,
        pagination: {
          ...props.pagination,
          pageIndex: props.pagination.pageIndex + 1
        }
      })
    );
  }, [queryClient, props]);

  return useQuery({
    ...getQueryOptions(props),
    placeholderData: keepPreviousData
  });
};

export default useGetPaginatedEquipments;
