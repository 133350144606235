import NoData from '@/components/common/no-data/NoData';
import ChooseService from '@/components/forms/form-choose-service-v3/ChooseService';
import FormChooseServiceV3 from '@/components/forms/form-choose-service-v3/FormChooseServiceV3';
import FormChooseService from '@/components/forms/form-choose-service/FormChooseService';
import { Skeleton } from '@/components/ui/skeleton';
import useGetOrgCategoriesWIthServices from '@/hooks/queries/services-api/useGetOrgCategoriesWithServices';
import useGetServicesEnabled from '@/hooks/queries/services-api/useGetServicesEnabled';
import { VEPCodes } from '@/lib/utils';
import type { TOrganizationFAQ } from '@/services/organization';
import { getFeatureFlagValue } from '@/utils/utils';
import type React from 'react';

const ChooseServiceStep: React.FC<{ organization: TOrganizationFAQ }> = ({
  organization
}) => {
  const { data, isPending } = useGetServicesEnabled();
  const {
    data: categoriesWithServices,
    isPending: isPendingCategoriesWithServices
  } = useGetOrgCategoriesWIthServices(organization.id);

  const featureFlagCrudValue = getFeatureFlagValue('newBooking');

  if (isPending || isPendingCategoriesWithServices) {
    return (
      <>
        <Skeleton className="w-full h-4" />
        <Skeleton className="w-full h-10 mt-8" />
        <Skeleton className="w-full h-10 mt-8" />
        <Skeleton className="w-full h-10 mt-8" />
      </>
    );
  }

  if (!data || !categoriesWithServices) return <NoData />;

  if (featureFlagCrudValue === 'v3') {
    return (
      <>
        {VEPCodes.includes(organization.code)? (
          <ChooseService services={categoriesWithServices} />
        ) : (
          <FormChooseServiceV3
            services={categoriesWithServices}
            organization={organization}
          />
        )}
      </>
    );
  }

  return <FormChooseService services={data} organization={organization} />;
};

export default ChooseServiceStep;
