import NewBookingSummaryModal from '@/components/common/modals/new-booking-summary-modal/NewBookingSummaryModal';
import FormParticipantDataAccordion from '@/components/forms/form-participant-data-accordion/FormParticipantDataAccordion';
import FormParticipantDataVp from '@/components/forms/form-participant-data-vp/FormParticipantDataVp';
import FormParticipantData from '@/components/forms/form-participant-data/FormParticipantData';
import type { TForm } from '@/components/forms/form-participant-data/FormParticipantData.types';
import { Skeleton } from '@/components/ui/skeleton';
import useCreateSale from '@/hooks/queries/sales-api/useCreateSale';
import { useGetUserByEmail } from '@/hooks/queries/users-api/useGetUserByEmail';
import { getAgeRange, VEPCodes } from '@/lib/utils';
import { useRootStore } from '@/stores/root-store';
import type { StepService } from '@/stores/slices/step-service/step-service.types';
import { getFeatureFlagValue } from '@/utils/utils';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import type ParticipantsDataStepProp from './ParticipantsDataStep.types';

const ParticipantsDataStep: React.FC<ParticipantsDataStepProp> = ({
  organization
}) => {
  const stepServicesData: StepService | null = useRootStore(
    (store) => store.stepServicesData
  );
  const loadStepServicesData = useRootStore(
    (store) => store.loadStepServicesData
  );

  const featureFlags = getFeatureFlagValue('formParticipants');
  const featureFlagsServiceList = getFeatureFlagValue('newBooking');

  const [openSummaryModal, setOpenSummaryModal] = useState(false);

  // check if user already exsist
  const [emailUser, setEmailUser] = useState('');
  const {
    data: user,
    isFetching: isUserPending,
    refetch: checkUserEmail,
    isError,
    error
  } = useGetUserByEmail(emailUser);

  // post
  const { mutate, isPending } = useCreateSale();

  const onSubmit = (data: TForm) => {
    // parse birthdate to dateISOString
    const participantsData = data.participants.map((participant) => {
      const { id, ...rest } = participant;
      if (id) return id;
      return {
        ...rest,
        birthdate: participant.birthdate.toISOString()
      };
    });

    const userParticipantData = {
      ...data.user,
      birthdate: data.user.birthdate ? data.user.birthdate.toISOString() : '', // parte date to ISO string
      isOwnUser: true // add value to indicate de user is participant
    };

    delete (userParticipantData as { userIsParticipant?: boolean })
      .userIsParticipant; // the end-point does not support this value

    const consumers = data.user.userIsParticipant
      ? [userParticipantData, ...participantsData]
      : participantsData;

    loadStepServicesData({
      email: data.user.email,
      phone: data.user?.phone ? data.user?.phone : undefined,
      firstName: data.user?.firstName,
      lastName: data.user?.lastName,
      identification: data?.user?.identification,
      consumers,
      paymentType: 'ef75aed8-40fb-489f-963c-11722aa12440'
    });

    setOpenSummaryModal(true);
  };

  const onSubmitAdditionalData = (data: any) => {
    const group = getAgeRange(data.user.birthdate);

    const consumers = [
      {
        ...data.user,
        group: `group-${group}`,
        ...data
      }
    ];

    const userData = data.parents[0];

    const submitValue = {
      email: userData?.email,
      phone: userData?.phone ? userData?.phone : undefined,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      identification: userData?.identification,
      consumers,
      paymentType: 'ef75aed8-40fb-489f-963c-11722aa12440',
      service: (stepServicesData as StepService).service?.id
    };

    mutate(submitValue);
  };

  useEffect(() => {
    if (emailUser) {
      checkUserEmail();
    }
  }, [emailUser, checkUserEmail]);

  useEffect(() => {
    if (isError) {
      if (error.status === 404) {
        toast.warning(t('toast.userNotFound'));
      }
    }
  }, [isError, error]);

  const renderForm = () => {
    if (isUserPending)
      return (
        <div>
          <Skeleton className="w-full h-5" />
          <Skeleton className="w-full h-5 mt-1" />
          <Skeleton className="w-full h-20 mt-5" />
          <Skeleton className="w-full h-8 mt-5" />
        </div>
      );

    if (featureFlagsServiceList === 'v3') {
      if (VEPCodes.includes(organization.code)) {
        return (
          <FormParticipantDataVp
            user={user}
            organization={organization}
            isPending={Boolean(isPending)}
            onSubmit={onSubmitAdditionalData}
          />
        );
      }
    }
    if (featureFlags === 'accordion')
      return (
        <FormParticipantDataAccordion
          user={user}
          setEmailUser={setEmailUser}
          emailUser={emailUser}
          isPending={Boolean(isPending)}
          onSubmit={onSubmit}
        />
      );

    return (
      <FormParticipantData
        user={user}
        organization={organization}
        setEmailUser={setEmailUser}
        emailUser={emailUser}
        isPending={Boolean(isPending)}
        onSubmit={onSubmit}
      />
    );
  };

  return (
    <>
      {openSummaryModal && (
        <NewBookingSummaryModal
          user={user}
          open={openSummaryModal}
          onOpenChange={setOpenSummaryModal}
          organization={organization}
          isPending={Boolean(isPending)}
          onSubmit={() => {
            const submitValue = stepServicesData as StepService;
            delete submitValue?.participants; // remove unnecessary value for the submit
            mutate({
              ...submitValue,
              service: submitValue?.service?.id
            });
          }}
        />
      )}
      {renderForm()}
    </>
  );
};

export default ParticipantsDataStep;
