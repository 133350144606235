import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import LanguageChanger from './LanguageChangerButton/LanguageChanger';
import { UserNav } from './UserNav';
import { SidebarTrigger } from '@/components/ui/sidebar';

const Navbar = () => {
  const { data: organization } = useGetOrganization();
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 w-full bg-accent">
      <div className="flex flex-row items-center justify-between gap-4 px-4 py-2 md:px-6">
        <div className="flex items-center gap-4">
          <SidebarTrigger />
          <div className="flex flex-col items-start ">
            <h1 className="text-base font-extrabold text-primary md:text-xl">
              {organization?.name}
            </h1>
            <p className="text-xs font-thin">
              Powered by{' '}
              <span className="font-semibold text-primary">Zazu</span>
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4 ">
          <div className="hidden md:block">
            <LanguageChanger />
          </div>
          <UserNav />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
