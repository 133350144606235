import { z } from 'zod';

const cancelSaleSchema = () =>
  z.object({
    observation: z
      .string({ required_error: 'schemas.cancelSale.observationRequired' })
      .min(10, 'schemas.cancelSale.observationMin')
  });

export default cancelSaleSchema;
