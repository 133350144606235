import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import type { GetConsumersProps } from '@/services/consumer';
import {
  keepPreviousData,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { useEffect } from 'react';

const getQueryOptions = ({
  pagination,
  filtering,
  sorting,
  columnFilters,
  organizationName
}: GetConsumersProps) => ({
  queryKey: [
    'consumers',
    pagination,
    { search: filtering },
    sorting,
    columnFilters,
    organizationName
  ],
  queryFn: () =>
    api.consumer.getPaginatedConsumer({
      pagination,
      filtering,
      sorting,
      columnFilters
    })
});

const useGetPaginatedConsumers = (props: GetConsumersProps) => {
  const organizationName = useOrganizationName();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      getQueryOptions({
        ...props,
        organizationName,
        pagination: {
          ...props.pagination,
          pageIndex: props.pagination.pageIndex + 1
        }
      })
    );
  }, [queryClient, props, organizationName]);

  return useQuery({
    ...getQueryOptions({ ...props, organizationName }),
    placeholderData: keepPreviousData
  });
};

export default useGetPaginatedConsumers;
