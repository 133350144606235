import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import useGetPaginatedReservations from '@/hooks/queries/reservations-api/useGetPaginatedReservation';
import useGetReservationsByServiceId from '@/hooks/queries/reservations-api/useGetReservationByServiceId';
import useGetOrganizationServices from '@/hooks/queries/services-api/useGetOrganizationServices';
import useCalendarPaginateNavigation from '@/hooks/useCalendarPaginateNavigation';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import { getGroup } from '@/lib/utils';
import type { TServices } from '@/services/services';
import { ReservationStatus } from '@/utils/reservations';
import { differenceInYears, format, isValid } from 'date-fns';
import { ArrowRight } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import ErrorMessage from '../../error-message/ErrorMessage';
import CalendarModal from '../../modals/calendar-modal/CalendarModal';
import StudentStatusEmojis from '../../studentStatusEmojis/StudentStatusEmojis';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import type { ReservationInterface } from '../tables.types';
import {
  exportAttendanceToExcel,
  exportDataOfStudentsToExcel
} from './exportToExcel';
import PresentCheckbox from './PresentCheckbox';
import TableToolBar from './TableToolBar';

export type Student = {
  estado: string;
  alumno: string;
  presente: boolean;
  contacto: string;
  fechaNacimiento: Date;
  observaciones: string;
};

const getTodayDateFormatted = (date: Date | undefined): string => {
  if (!date || !isValid(date)) {
    return 'Fecha no válida';
  }
  return format(date, 'dd/MM');
};
const StudentTables: React.FC = () => {
  const { t } = useTranslation();

  const { data: services } = useGetOrganizationServices<TServices[]>();
  const SERVICE_ID =
    services?.filter((item) => item.enabled === true)[0].id || [];

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'startTime', desc: false }]
  });

  const { date, setDay } = useCalendarPaginateNavigation(new Date());
  const TIMESTAMP_INIT = date
    ? new Date(date.setHours(0, 0, 0, 0)).getTime()
    : new Date().getTime();
  const TIMESTAMP_END = date
    ? new Date(date.setHours(23, 59, 59, 999)).getTime()
    : new Date().getTime();

  const {
    data: attendance,
    isLoading,
    isError,
    isFetching
  } = useGetPaginatedReservations({
    pagination,
    filtering,
    sorting,
    query: '&showConsumerConcurrent=true',
    columnFilters: [
      ...columnFilters,
      { id: 'sale.service.id', value: SERVICE_ID },
      { id: 'startTime', value: { from: TIMESTAMP_INIT, to: TIMESTAMP_END } }
    ]
  });

  const [rowSelection, setRowSelection] = useState({});
  const reservationsData = attendance?.results;

  const { data: allReservations, isLoading: isLoadingAllReservations } =
    useGetReservationsByServiceId(String(SERVICE_ID));

  const handleExport = async () => {
    try {
      exportDataOfStudentsToExcel(allReservations);
    } catch (error) {
      toast.error(t('toast.downloadError'));
    }
  };

  const handleExportAttendance = async () => {
    try {
      exportAttendanceToExcel(allReservations as ReservationInterface[]);
    } catch (error) {
      toast.error(t('toast.downloadError'));
    }
  };

  const columns = useMemo<ColumnDef<ReservationInterface>[]>(
    () => [
      {
        id: 'state',
        accessorKey: 'state',
        header: t('activity.table.state'),
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          const studentData = row.original.consumers[0];
          return <StudentStatusEmojis studentData={studentData} />;
        }
      },
      {
        id: 'consumers.firstName',
        accessorKey: 'consumers.firstName',
        enableSorting: false,
        accessorFn: (originalRow) =>
          originalRow.consumers
            ? `${originalRow.consumers[0]?.firstName} ${originalRow.consumers[0]?.lastName}`
            : undefined,
        header: t('activity.table.student')
      },
      {
        id: 'reservationStatus.name',
        accessorKey: 'reservationStatus.name',
        meta: { headerText: t('activity.table.attend') },
        enableSorting: false,
        header: () => (
          <div className="flex items-center gap-2">
            <span>{`${t('activity.table.attend')} (${getTodayDateFormatted(date)})`}</span>
            <CalendarModal
              placeholder=""
              value={undefined}
              disabled={date}
              defaultMonth={date}
              onAccept={(selectedDate) => {
                if (selectedDate) setDay(selectedDate);
              }}
            />
          </div>
        ),
        cell: ({ row }) => {
          const reservation = row.original;
          const status = reservation.reservationStatus.name;
          const checkPresent = status === ReservationStatus.Completed;
          return (
            <PresentCheckbox
              reservationId={reservation.id}
              checkPresent={checkPresent}
            />
          );
        },
        enableColumnFilter: true
      },
      {
        id: 'contact',
        accessorKey: 'contact',
        header: t('activity.table.contact'),
        enableSorting: false,
        accessorFn: (originalRow) => {
          const additionalData = originalRow.consumers[0]?.additionalData;
          return additionalData
            ? `${additionalData?.parents[0].firstName} ${additionalData?.parents[0].lastName}`
            : undefined;
        }
      },
      {
        id: 'sale.saleConsumers.consumer.additionalData',
        accessorKey: 'sale.saleConsumers.consumer.additionalData',
        enableSorting: false,
        accessorFn: (originalRow) => {
          const additionalData = originalRow.consumers[0]?.additionalData;
          return getGroup(additionalData);
        },
        header: t('activity.student.group')
      },
      {
        id: 'sale.saleConsumers.consumer.birthdate',
        accessorKey: 'sale.saleConsumers.consumer.birthdate',
        accessorFn: (originalRow) => {
          return originalRow.consumers[0]?.birthdate
            ? differenceInYears(
                new Date(),
                new Date(originalRow.consumers[0]?.birthdate)
              )
            : undefined;
        },
        header: t('activity.student.age')
      },
      {
        id: 'obvervations',
        accessorKey: 'obvervations',
        enableSorting: false,
        header: t('activity.table.observation'),
        accessorFn: (originalRow) => {
          const additionalData = originalRow.consumers[0]?.additionalData;
          return additionalData ? additionalData?.observations : undefined;
        }
      },
      {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }) =>
          row.original.consumers && (
            <Link
              to={`students/${row.original.consumers[0]?.id}`}
              className="w-full"
            >
              <ArrowRight />
            </Link>
          )
      }
    ],
    [date, t]
  );

  const table = useReactTable({
    data: reservationsData ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),

    // pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    rowCount: attendance?.total,
    pageCount: Math.ceil((attendance?.total || 0) / (attendance?.limit || 1)),
    manualPagination: true, //turn off client-side pagination

    // Sort configuration
    onSortingChange: setSorting,
    enableMultiSort: false,
    manualSorting: true,
    sortDescFirst: true,
    //filter configuration
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    //row selection
    onRowSelectionChange: setRowSelection,
    initialState: {
      columnVisibility: {
        id: false
      }
    },
    state: {
      columnFilters,
      rowSelection,
      pagination,
      sorting
    }
  });

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-col items-center w-full gap-4 mt-6 sm:gap-2 sm:flex-row">
        <Button
          onClick={handleExport}
          disabled={isLoadingAllReservations || !allReservations}
          className="w-full sm:w-auto"
        >
          {t('common.downloadStudentsData')}
        </Button>
        <Button
          onClick={handleExportAttendance}
          disabled={isLoadingAllReservations || !allReservations}
          className="w-full sm:w-auto"
        >
          {t('common.downloadAttendanceSheet')}
        </Button>
      </div>
      <TableToolBar
        table={table}
        filtering={filtering}
        setFiltering={setFiltering}
        isFetching={isFetching}
      />
      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />
    </div>
  );
};

export default StudentTables;
