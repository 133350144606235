import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

const Pagination = ({
  actualPage,
  totalPages,
  onPreviousPage,
  onNextPage,
  isPreviousPageAvailable,
  isNextPageAvailable,
  results
}: {
  actualPage: number;
  totalPages: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  isPreviousPageAvailable: boolean;
  isNextPageAvailable: boolean;
  results: boolean;
}) => {
  const { t } = useTranslation();
  if (!results) return null;
  return (
    <div className="flex flex-col justify-between gap-4 mt-4 w-fullitems-center sm:flex-row">
      <div className="flex items-center justify-center gap-1">
        <p className="text-sm">{t('pagination.page')}</p>
        <p className="text-sm">
          {actualPage} {t('pagination.of')} {totalPages}
        </p>
      </div>
      <div className="flex gap-2">
        <Button
          variant="outline"
          size="sm"
          onClick={onPreviousPage}
          disabled={!isPreviousPageAvailable}
          className="w-full"
        >
          {t('pagination.previous')}
        </Button>

        <Button
          variant="outline"
          size="sm"
          onClick={onNextPage}
          disabled={!isNextPageAvailable}
          className="w-full"
        >
          {t('pagination.next')}
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
