import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { FilterIcon } from 'lucide-react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const TableFilters = ({
  filterActive,
  children,
  dropdownMenuContentClassName
}: {
  filterActive: boolean;
  children: ReactNode;
  dropdownMenuContentClassName?: string;
}) => {
  const { t } = useTranslation();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex items-center gap-2" asChild>
        <Button
          variant={filterActive ? 'default' : 'outline'}
          className="w-full sm:w-auto"
        >
          <FilterIcon className="w-4 h-4" /> <p>{t('common.filters')}</p>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className={dropdownMenuContentClassName}
      >
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default TableFilters;
