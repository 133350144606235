import { Checkbox } from '@/components/ui/checkbox';
import useReservationActions from '@/hooks/useReservationActions';
import type React from 'react';

const PresentCheckbox: React.FC<{
  reservationId: string;
  checkPresent: boolean;
}> = ({ reservationId, checkPresent }) => {
  const { handleAbsent, handleCompleted, isPending } = useReservationActions({
    disabledRefetch: true
  });

  const handlePresent = () => {
    if (checkPresent) {
      handleAbsent(reservationId);
    } else {
      handleCompleted(reservationId);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center pr-4">
        <Checkbox
          className="w-5 h-5 "
          checked={checkPresent}
          onClick={() => handlePresent()}
          disabled={isPending}
        />
      </div>
    </div>
  );
};

export default PresentCheckbox;
